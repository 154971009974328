
import { defineComponent, ref } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import { formattedDatesType } from "@/helpers/types/date-picker.types";
import GenerateReportBlock from "@/views/Reports/components/GenerateReportBlock.vue";
import {
  formatBlobErrorToMsg,
  objectPropertiesToQuery,
} from "@/helpers/utils/format.utils";
import { AxiosResponse } from "axios";
import reportsApi from "@/api/reports.api";
import { downloadBlob } from "@/helpers/utils/download.utils";

export default defineComponent({
  name: "RefundsReportPage",
  components: {
    GenerateReportBlock,
    GoBackIcon,
  },

  setup() {
    const isGeneratingReport = ref(false);
    const generateReport = async (dates: formattedDatesType) => {
      isGeneratingReport.value = true;
      try {
        const query = objectPropertiesToQuery(dates);
        const response: AxiosResponse = await reportsApi.getRefundsReport(
          query
        );
        const fileName = `wipon-refunds_${dates.from}-${dates.to}`;
        downloadBlob(response, fileName);
      } catch (e: any) {
        await formatBlobErrorToMsg(e);
      } finally {
        isGeneratingReport.value = false;
      }
    };

    return {
      isGeneratingReport,
      generateReport,
    };
  },
});
